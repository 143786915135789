<template>
    <div class="schoolRank">
        <div class="head-contain">
            <div class="head-contain-center">
                <div class="title">
                    <div class="title-icon">
                        <el-image class="elimage" :src="image" />
                    </div>
                    <div class="title-text">加盟成员</div>
                </div>
            </div>
        </div>
        <div class="content">
            <vueSwiper :slide-list="cardList" currentIndex="1" isColor="fff">
            </vueSwiper>
        </div>
    </div>
</template>
  
<script>
import vueSwiper from '@/components/vueSwiper'
export default {
    name: "schoolRank",
    components: {
        vueSwiper
    },
    data() {
        return {
            image: require('@/assets/school/join.png'),
            cardList: [
                {
                    imgSrc: require('@/assets/school/1.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/2.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/3.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/4.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/5.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/6.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/7.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/8.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/9.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/10.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/11.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/12.jpg'),
                },
                {
                    imgSrc: require('@/assets/school/13.jpg'),
                }
            ]
        };
    },
    computed: {},
    created() {
    },
    methods: {
    },
};
</script>
<style scoped lang="scss">
.schoolRank {
    width: 1760px;
    height: 100%;

    .head-contain {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .head-contain-left {
            height: 100%;
            width: 64px;
        }

        .head-contain-center {
            height: 100%;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;

                .title-icon {
                    margin-right: 10px;
                    width: 36px;
                    height: 36px;

                    .elimage {
                        width: 100%;
                        height: 100%;
                    }
                }

                .title-text {
                    // margin-left: 5px;
                    font-size: 30px;

                    font-weight: bold;
                    color: #000000;
                }
            }
        }
    }

    .content {
        // border: 1px solid rgb(0, 255, 0);
        // height: 365px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

    }
}
</style>
  