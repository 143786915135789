<template>
  <div class="firstpage">
    <div class="main-contain">
      <div class="elcarousel">
        <Carousel :isPosition="0"></Carousel>
      </div>
      <div class="elLiveStreaming">
        <liveStreaming></liveStreaming>
      </div>
      <div class="virtualsimulation-experiment">
        <Virtualsimulationexperiment></Virtualsimulationexperiment>
      </div>
      <div class="recommended-courses">
        <Recommendedcourses></Recommendedcourses>
      </div>
      <div class="schoolRank">
        <schoolRank></schoolRank>
      </div>
    </div>
    <el-dialog title="完善个人信息" :visible.sync="dialogVisible" width="30%" top="40vh" :show-close="false"
      :close-on-click-modal="false">
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我是学生" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="我是教师" name="second">配置管理</el-tab-pane>
      </el-tabs> -->
      <div class="tabs_cont">
        <div v-for="(item, inx) in tabsList" :key="inx" class="item" :class="item.check ? 'active' : ''"
          @click="checkTabs(item, inx)">
          {{ item.label }}</div>
      </div>
      <div v-if="courses_flag" style="margin-top: 30px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="学校" prop="school_sn">
            <el-select v-model="ruleForm.school_sn" filterable placeholder="请选择" style="width:100%"
              @change="handleChange">
              <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.school_sn">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="学号" prop="user_sn">
            <el-input v-model="ruleForm.user_sn"></el-input>
          </el-form-item>
          <el-form-item label="学院" prop="college_name">
            <el-input v-model="ruleForm.college_name"></el-input>
          </el-form-item>
          <el-form-item label="专业" prop="major_name">
            <el-input v-model="ruleForm.major_name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="vr_flag" style="margin-top: 30px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="学校" prop="school_sn">
            <el-select v-model="ruleForm.school_sn" filterable placeholder="请选择" style="width:100%"
              @change="handleChange">
              <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.school_sn">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="教工号" prop="user_sn">
            <el-input v-model="ruleForm.user_sn"></el-input>
          </el-form-item>
          <el-form-item label="学院" prop="college_name">
            <el-input v-model="ruleForm.college_name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="bb">取 消</el-button> -->
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Carousel from "@/views/firstpage/components/carousel.vue";
import Recommendedcourses from "@/views/firstpage/components/recommendedcourses.vue";
import Virtualsimulationexperiment from "@/views/firstpage/components/virtualsimulationexperiment.vue";
import liveStreaming from "@/views/firstpage/components/liveStreaming.vue"
import schoolRank from "@/views/firstpage/components/schoolRank.vue"
import { getSchoolList } from "@/api/vr";
import { userComplete } from "@/api/user";
import {
  setSchoolId,
  removeSchoolId,
  setIdentifierToken,
  removeIdentifierToken
} from "@/utils/auth";
export default {
  name: "FirstPage",
  components: {
    Carousel,
    Recommendedcourses,
    Virtualsimulationexperiment,
    liveStreaming,
    schoolRank
  },
  data() {
    return {
      dialogVisible: false,
      activeName: 'second',
      headers: {}, // 请求头
      uploadSrc: process.env.VUE_APP_BASE_API + "/portal/user/complete",
      schoolId: '',//学校id
      tabsList: [
        {
          code: 0,
          label: '我是学生',
          check: true,
        },
        {
          code: 1,
          label: '我是教师',
          check: false,
        }
      ],
      vr_flag: false,
      courses_flag: true,
      schoolList: [],
      ruleForm: {
        identify: 1, // 用户身份：1：学生，2：老师
        name: '', // 姓名
        school_sn: '', //学校编码
        college_name: '', //学院
        major_name: '', //专业
        user_sn: '' //学工号
      },
      rules: {
        school_sn: [
          { required: true, message: '请选择学校', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],

      }
    };
  },
  created() {
    let network = localStorage.getItem("network")
    if (!network) {
      let ip = "s3cluster2.hzau.edu.cn";
      this.ping(ip)
    }
    let authentication = localStorage.getItem("authentication")
    if (authentication == 1) {
      this.dialogVisible = true
    }
    this.getSchoolList()
  },
  methods: {
    checkTabs(item, inx) {
      this.tabsList.forEach(el => {
        el.check = false
      })
      item.check = !item.check

      if (inx) {
        this.vr_flag = true
        this.courses_flag = false
        this.ruleForm.identify = 2
      } else {
        this.vr_flag = false
        this.courses_flag = true
        this.ruleForm.identify = 1
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleChange(res) {
      this.schoolList.forEach((item) => {
        if (item.school_sn == res) {
          this.schoolId = item.id
        }
      })
    },
    selectSchool() {
      // this.getSchoolList()
    },
    //获取学校列表
    getSchoolList() {
      getSchoolList().then((response) => {
        if (response.code == 0) {
          this.schoolList = response.data
        }
      })
    },
    ping(ip) {
      var img = new Image();
      var start = new Date().getTime();
      var flag = false;
      var isCloseWifi = true;
      var hasFinish = false;
      img.onload = function () {
        if (!hasFinish) {
          flag = true;
          hasFinish = true;
          console.log('Ping ' + ip + ' success. ');
          // alert("成功a"+ip);
          localStorage.setItem("network", 1)
        }
      };
      img.onerror = function () {
        if (!hasFinish) {
          if (!isCloseWifi) {
            flag = true;
            console.log('Ping ' + ip + ' success. ');
            // alert("成功b"+ip);
            localStorage.setItem("network", 1)
          } else {
            console.log('network is not working!');
            localStorage.setItem("network", 0)
          }
          hasFinish = true;
        }
      };
      setTimeout(function () {
        isCloseWifi = false;
        console.log('network is working, start ping...');
        // alert("开始测试"+ip);
      }, 2);
      img.src = 'http://' + ip + '/' + start;
      var timer = setTimeout(function () {
        if (!flag) {
          hasFinish = true;
          flag = false;
          console.log('Ping ' + ip + ' fail. ');
          // alert("失败c"+ip);
          localStorage.setItem("network", 0)
        }
      }, 3000);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let token = 'Bearer ' + getToken()
          userComplete(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "完善个人信息成功",
                type: "success",
              });
              this.dialogVisible = false
              localStorage.removeItem("authentication")
              removeIdentifierToken()
              removeSchoolId()
              // removeToken()
              // setToken(this)
              setSchoolId(this.schoolId)
              setIdentifierToken(this.ruleForm.identify)
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
.firstpage {
  background: #ffffff;

  .tabs_cont {
    width: 344px;
    height: 50px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px auto 0px;

    .item {
      width: calc(100% / 2);
      text-align: center;
      height: 50px;
      line-height: 50px;
      background-color: #F3F4F9;
      box-shadow: 0px 0px 5px 0px rgba(43, 43, 43, 0.05);
      font-size: 16px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
    }

    .active {
      background-color: #E6F1FF;
      color: #0773FC;
    }
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 30px 20px;
  }

  .main-contain {
    .elcarousel {
      margin: 0 auto;
    }

    .notice-contain {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .recommended-courses {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto 50px;
    }

    .elLiveStreaming {
      margin: 50px auto;
    }

    .virtualsimulation-experiment {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .open-room {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .openexp-eriment {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .excellent-video {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .leader-board {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }

    .schoolRank {
      margin: 0px auto 30px;
    }
  }
}
</style>
