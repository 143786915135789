<template>
  <div class="live_cont" v-if="liveShow">
    <div class="head-contain">
      <div class="head-contain-center">
        <div class="title">
          <div class="title-icon">
            <el-image class="elimage" :src="liveBro" />
          </div>
          <div class="title-text">最近直播</div>
        </div>
      </div>
      <div class="head-contain-right">
        <div class="more-contain">
          <div class="more" @click="tomore">
            <div class="more-title">更多</div>
            <div class="more-icon">
              <el-image class="elimage" :src="moreicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="timeLineCont">
      <el-divider class="lineCont"></el-divider>
      <div class="playCont" v-for="(v, i) in videoList" :key="i">
        <div class="svgBg">
          <svg width="20" height="20" viewBox="-50 -50 300 300">
            <polygon class="triangle" :class="!i ? 'active' : ''" stroke-linejoin="round"
              points="100,0 0,200 200,200" />
          </svg>
        </div>
        <div class="timeText" :class="!i ? 'active' : ''">{{ v.date }}<span v-if="!i">今日</span></div>
        <div class="list-li">
          <div class="list-li-arrow-wrap">
            <!-- <div class="list-li-arrow"></div> -->
          </div>
          <div class="list-li-panel" :class="!v.list.length ? 'empty-cont' : ''">
            <div class="block" v-if="v.list.length">
              <el-timeline>
                <el-timeline-item v-for="(el, inx) in v.list" :key="inx" size="large"
                  :timestamp="el.start_time | dataformat" placement="top">
                  <div class="list-li-select" @click="todetail(el)">
                    <div class="list-imgCont">
                      <img :src="el.cover" alt="">
                    </div>
                    <div class="list-cont">
                      <div class="list-title" v-if="el.title.length < 42">{{ el.title }}</div>
                      <el-tooltip placement="top" effect="light" v-else>
                        <div slot="content">{{ el.title }}</div>
                        <div class="list-title">{{ el.title }}</div>
                      </el-tooltip>
                      <div class="info-entrance">
                        <div v-if="el.live_user_info">
                          <div>{{ el.live_user_info.username }}<span
                              v-if="(el.live_user_info.username && el.school_name)" style="padding: 0px 4px;">|</span>{{
      el.school_name
                              }}</div>
                          <!-- <div v-if="el.live_user_info.username.length < 13">{{
                              el.live_user_info.username
                          }}</div>
                          <el-tooltip placement="top" effect="light" v-else>
                            <div slot="content">{{ el.live_user_info.username }}</div>
                            <div>{{ el.live_user_info.username }}</div>
                          </el-tooltip> -->
                        </div>
                        <!-- <el-button @click="todetail(el)">进入直播</el-button> -->
                      </div>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
            <!-- <el-empty :image-size="60" v-else></el-empty> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getRecentList } from "@/api/course";
export default {
  name: "liveStreaming",
  components: {},
  data() {
    return {
      moreicon: require("@/assets/new/right.png"),
      liveBro: require("@/assets/svg/live_broadcast.png"),
      videoList: [],
      liveShow: false,
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("HH:mm");
    }
  },
  computed: {},
  created() {

  },
  mounted() {
    this.initLive()
  },
  methods: {
    tomore() {
      window.localStorage.setItem("menuId", 3);
      this.$store.dispatch("setmenuid", 3);
      this.$router.push({
        path: "/home/livebroadcast/mainpage"
      });
    },
    timeFormat(data) { //补齐时间，返回格式为:yyyy.mm.dd
      let date = ''
      if (!data) {
        date = new Date()
      } else {
        date = this.dateValue
      }
      let strArr = date.toLocaleDateString().split('/')
      let timer = ''
      strArr.forEach((item, index) => {
        if (!index) {
          timer = item
        } else {
          if (parseInt(item) < 10) {
            item = '.0' + item
          } else {
            item = '.' + item
          }
          timer = timer + item
        }
      })
      return timer
    },

    getNextDate(day = 1, format = "{y}-{m}-{d}") { //当前日期后4天
      const nDate = new Date();
      nDate.setDate(nDate.getDate() + day);
      const formatObj = {
        y: nDate.getFullYear(),
        m: nDate.getMonth() + 1,
        d: nDate.getDate(),
      };
      return format.replace(/{([ymd])+}/g, (result, key) => {
        const value = formatObj[key];
        return value.toString().padStart(2, "0");
      });
    },
    initLive() {
      this.liveShow = false
      let start_time = parseInt(this.timeFormat(0).replaceAll('.', ''))
      let end_time = parseInt(this.getNextDate(3, "{y}{m}{d}"))
      let params = {
        start_date: start_time,
        end_date: end_time
      }
      getRecentList(params).then(res => {
        if (res.code === 0) {
          res.data.forEach(item => {
            if (item?.list?.length) {
              this.liveShow = true
            }
            this.videoList.push({
              date: item.date,
              list: item.list || []
            })
          })
        }
      })
    },
    todetail(item) {
      this.$router.push({
        path:
          "/home/livebroadcast/courselearning?sn=" +
          item.sn,
      });
      document.title = item.title
    },
  },
};
</script>
<style scoped lang="scss">
.live_cont {
  width: 1760px;
  height: 100%;
  margin: 0px auto;


  .head-contain {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .head-contain-left {
      height: 100%;
      width: 64px;
    }

    .head-contain-center {
      height: 100%;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;

        .title-icon {
          margin-right: 10px;
          width: 32px;
          height: 32px;

          .elimage {
            width: 100%;
            height: 100%;
          }
        }

        .title-text {
          // margin-left: 10px;
          font-size: 30px;

          font-weight: bold;
          color: #000000;
        }
      }
    }

    .head-contain-right {
      //   border: 1px solid red;
      height: 100%;

      //   width: 100px;
      .more-contain {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .more {
          width: 64px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .more-icon {
            margin-right: 10px;

            .elimage {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }

  .timeLineCont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .lineCont {
      position: absolute;
      top: 5px;
      height: 2px;
      background: #EBEEF5;
    }

    .svgBg {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background-color: rgba(248, 248, 251, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 50%;
      transform: translateX(-28px);

      svg {
        transform: rotate(-26deg);
      }

      .triangle {
        fill: rgba(222, 222, 222, 1);
        stroke: rgba(222, 222, 222, 1);
        stroke-width: 100;
      }

      .triangle.active {
        fill: rgba(7, 115, 252, 1);
        stroke: rgba(7, 115, 252, 1);
      }
    }

    .timeText {
      text-align: center;
      height: 26px;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      // padding: 6px 0px;
      color: #666666;
    }

    .timeText.active {
      font-size: 16px;
      color: rgba(7, 115, 252, 1);
      ;
    }

    .list-li {
      .list-li-arrow-wrap {
        height: 24px;
        overflow: hidden;
        position: relative;
      }

      .list-li-arrow {
        width: 20px;
        height: 20px;
        background: #ffffff;
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        position: absolute;
        left: 50%;
        transform: rotate(45deg) translateX(-12px);
        margin-top: 24px;
      }

      .list-li-panel {
        width: 389px;
        min-height: 154px;
        max-height: 423px;
        border-radius: 3px;
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        overflow-y: auto;
      }

      .empty-cont {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-timeline-item {
        padding-bottom: 20px;
      }
    }
  }



  .dialogTimeLine {
    min-width: 430px;
    padding: 30px 30px 30px 0px;

    .timelineTitle {
      font-size: 20px;
      font-weight: 400;
    }

    .timeline-cont {
      overflow-y: auto;
      min-height: 160px;
      max-height: 540px;
      margin-top: 16px;
    }

    .empty-cont {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .list-li-panel .list-li-select {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .list-imgCont {
      min-width: 140px;
      height: 96px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .list-cont {
      position: relative;
      padding-left: 10px;
      width: calc(100% - 130px);
      height: 96px;

      .list-title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        height: calc(100% - 30px);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
    }

    .info-entrance {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 30px;

      div {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
