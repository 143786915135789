<template>
    <div class="vueSwiper" @mouseenter="stopAutoPlay" @mouseleave="startAutoPlay">
        <div ref="mySwiper" class="swiper-container" :id="currentIndex">
            <div class="swiper-wrapper card-show-li">
                <div class="card-show-li-item swiper-slide" v-for="(el, inx) in slideList" :key="inx">
                    <img :src="el.imgSrc" class="card-image">
                </div>
            </div>
            <!--导航器-->
            <div class="swiper-button-prev">
                <el-button icon="el-icon-arrow-left" circle></el-button>
            </div>
            <div class="swiper-button-next">
                <el-button icon="el-icon-arrow-right" circle></el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper'
import "swiper/css/swiper.css";
export default {
    name: 'vueSwiper',
    props: ['slideList', 'currentIndex'],
    data() {
        return {
            currentSwiper: null,
        }
    },
    watch: {
        //slide数据发生变化时,更新swiper
        slideList: {
            deep: true,
            // eslint-disable-next-line
            handler(nv, ov) {
                console.log("数据更新了")
                this.updateSwiper()
            }
        }
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        //鼠标移入暂停自动播放
        stopAutoPlay() {
            this.currentSwiper.autoplay.stop()
        },
        //鼠标移出开始自动播放
        startAutoPlay() {
            this.currentSwiper.autoplay.start()
        },
        //初始化swiper
        initSwiper() {
            // eslint-disable-next-line
            let vueComponent = this//获取vue组件实例
            //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
            this.currentSwiper = new Swiper('#' + this.currentIndex, {
                loop: true, // 循环模式选项
                autoHeight: 'true',//开启自适应高度,容器高度由slide高度决定
                //分页器
                pagination: {
                },
                on: {
                    //此处this为swiper实例
                    //切换结束获取slide真实下标
                    slideChangeTransitionEnd: function () {
                        // console.log(vueComponent.$props.currentIndex + "号swiper实例真实下标", this.realIndex)
                    },
                    //绑定点击事件,解决loop:true时事件丢失
                    // eslint-disable-next-line
                    click: function (event) {
                        // console.log("你点击了" + vueComponent.$props.currentIndex + "号swiper组件")
                    }
                },
                //导航器
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    //自动播放,不同版本配置方式不同
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                slidesPerView: 7, //视口展示slide数1
                slidesPerGroup: 1, //slide数1页一组
            })
            // this.currentSwiper.autoplay.stop()

        },
        //销毁swiper
        destroySwiper() {
            try {
                this.currentSwiper.destroy(true, false)
            } catch (e) {
                console.log("删除轮播")
            }
        },
        //更新swiper
        updateSwiper() {
            this.destroySwiper()
            this.$nextTick(() => {
                this.initSwiper()
            })
        },
    },
    destroyed() {
        this.destroySwiper()
    }
}
</script>
<style scoped lang="scss">
$var: 1760px;
$width: calc((1760px - 140px) / 7);


/*swiper容器样式*/
.swiper-container {
    width: $var;
    --swiper-navigation-size: 0;
}

.swiper-button-prev {
    left: 4px;
}

.swiper-button-next {
    right: 4px;
}

.swiper-button-prev,
.swiper-button-next {
    .el-button {
        border-color: transparent;
        color: #fff;
        background-color: rgba(31, 45, 61, 0.4)
    }
}


.swiper-container .rot,
.swiper-container .rat {
    opacity: 0;
}

.swiper-container:hover .rot,
.swiper-container:hover .rat {
    opacity: 1;
}

.card-show-li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 110px !important;
}

.card-show-li-item {
    overflow: hidden;
    width: $width !important;
    height: 100px !important;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
    margin: 0px 10px;

    .card-image {
        object-fit: cover;
        height: 100px;
    }
}
</style>