<template>
  <div class="virtualsimulationexperiment">
    <div class="head-contain">
      <!-- <div class="head-contain-left"></div> -->
      <div class="head-contain-center">
        <div class="title">
          <div class="title-icon">
            <el-image class="elimage" :src="image" />
          </div>
          <div class="title-text">在线课程</div>
        </div>
      </div>
      <div class="head-contain-right">
        <div class="more-contain">
          <div class="more" @click="tocourse">
            <div class="more-title">更多</div>
            <div class="more-icon">
              <el-image class="elimage" :src="moreicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="showImg">
        <el-image class="elimage" :src="elimage" />
      </div>
      <div class="content_item_cont">
        <div class="content-item" v-for="(item, index) in courselist" :key="index">
          <div class="image-contain" @click="todetail(item)">
            <el-image class="elimage" :src="item.cover" />
            <!-- <el-image class="elimage" :src="randomimage()" fit="cover" /> -->
          </div>
          <div class="bottomcontain">
            <div class="title1">{{ item.name }}</div>
            <div class="title2">
              <div class="name">
                {{ item.teacher_user_info ? item.teacher_user_info.name : "" }}<span
                  v-if="(item.teacher_user_info && item.school_name)" style="padding: 0px 4px;">|</span>{{
                      item.school_name
                  }}
              </div>
              <!-- <div class="rightimage">
                <span class="text">{{ item.join_count }}人</span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="empty-item" v-for="(item, index) in emptylist" :key="index + '_dd34_ds_k'"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseList, getcourseinfo } from "@/api/course";
export default {
  name: "Virtualsimulationexperiment",
  components: {},
  data() {
    return {
      courselist: [],
      emptylist: [],
      image: require("@/assets/new/推荐课程.png"),
      moreicon: require("@/assets/new/right.png"),
      testimage: require("@/assets/test.png"),
      image3: require("@/assets/new/people.png"),
      image01: require("@/assets/new2/1.jpg"),
      image02: require("@/assets/new2/2.jpg"),
      image03: require("@/assets/new2/3.jpg"),
      image04: require("@/assets/new2/4.jpg"),
      image05: require("@/assets/new2/5.jpg"),
      image06: require("@/assets/new2/6.jpg"),
      image07: require("@/assets/new2/7.jpg"),
      image08: require("@/assets/new2/8.jpg"),
      image09: require("@/assets/new2/9.jpg"),
      image10: require("@/assets/new2/10.jpg"),
      elimage: require("@/assets/boutique.jpg"),
    };
  },
  computed: {},
  created() {
    this.getCourseList({
      per_page: 12,
      page: 1,
      show_index: 1,
    });
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    tocourse() {
      window.localStorage.setItem("menuId", 2);
      this.$store.dispatch("setmenuid", 2);
      this.$router.push({
        path: "/home/wisdompracticeteaching/mainpage",
      });
    },
    todetail(item) {
      window.localStorage.setItem("menuId", 2);
      this.$store.dispatch("setmenuid", 2);
      if (item.is_join == 1) {
        //已经加入课程
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" +
            item.course_sn,
        });
        // this.getcourseinfo({
        //   course_sn: item.course_sn,
        // });
      } else {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/coursedetails?course_sn=" +
            item.course_sn,
        });
      }
    },

    getCourseList(params) {
      this.courselist = [];
      this.emptylist = [];
      getCourseList(params)
        .then((response) => {
          if (response.data && response.data.data) {
            let itemlist = response.data.data;
            for (let i = 0; i < 12; i++) {
              if (itemlist[i]) {
                this.courselist.push(itemlist[i]);
              } else {
                this.emptylist.push({});
              }
            }
          }
        })
        .catch((error) => { });
    },

    getcourseinfo(params) {
      getcourseinfo(params)
        .then((response) => {
          if (response.code == 0) {
            let obj = JSON.stringify(response.data);
            this.$router.push({
              path: "/home/wisdompracticeteaching/courselearning",
              query: {
                courseinfo: encodeURIComponent(obj),
              },
            });
          }
          // this.courseinfo = response.data;
        })
        .catch((error) => { });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulationexperiment {
  width: 1760px;
  height: 100%;

  .head-contain {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .head-contain-left {
      height: 100%;
      width: 64px;
    }

    .head-contain-center {
      height: 100%;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;

        .title-icon {
          margin-right: 10px;
          width: 32px;
          height: 32px;

          .elimage {
            width: 100%;
            height: 100%;
          }
        }

        .title-text {
          // margin-left: 5px;
          font-size: 30px;

          font-weight: bold;
          color: #000000;
        }
      }
    }

    .head-contain-right {
      //   border: 1px solid red;
      height: 100%;

      //   width: 100px;
      .more-contain {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .more {
          width: 64px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .more-icon {
            margin-right: 10px;

            .elimage {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }

  .content {
    // border: 1px solid rgb(0, 255, 0);
    // height: 365px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .showImg {
      width: calc((100% - 140px) / 7);
      height: calc((212px * 2) + 25px);
      overflow: hidden;

      .elimage {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    }

    .content_item_cont {
      width: calc(100% - ((100% - 140px) / 7));
    }

    .content-item {
      border: 1px solid transparent;
      box-sizing: border-box;
      // height: 205px;
      cursor: pointer;
      margin-left: 20px;
      width: calc((100% - 120px) / 6);
      float: left;
      cursor: pointer;
      border-radius: 5px 5px 0px 0px;

      .image-contain {
        width: 100%;
        height: 140px;
        overflow: hidden;
        border-radius: 5px;

        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        .elimage {
          display: inline-block;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          border-radius: 5px;
        }

        .elimage:hover,
        .elimage:focus,
        .elimage:active {
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }

        .elimage {
          width: 100%;
          height: 100%;
        }
      }

      .bottomcontain {
        padding: 20px 0px 0px;
        background: #ffffff;

        .title1 {
          // margin-left: 10px;
          text-align: left;
          font-size: 16px;
          text-overflow: ellipsis;
          font-weight: 500;
          color: #222222;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }

        .title2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            // margin-left: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
          }

          .rightimage {
            position: relative;
            width: 100px;
            height: 24px;

            .elimage {
              width: 100%;
              height: 100%;
            }

            .text {
              position: absolute;
              font-size: 12px;
              width: 100%;
              text-align: center;
              color: #666666;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .content-item:nth-child(n+7) {
      margin-top: 25px;
    }

    .empty-item {
      // border: 1px solid red;
      height: 100%;
      width: 330px;
      margin: 0px 10px;

      flex-shrink: 1;
    }
  }
}
</style>
