<template>
  <div class="virtualsimulationexperiment">
    <div class="head-contain">
      <!-- <div class="head-contain-left"></div> -->
      <div class="head-contain-center">
        <div class="title">
          <div class="title-icon">
            <el-image class="elimage" :src="image" />
          </div>
          <div class="title-text">虚拟仿真实验</div>
        </div>
      </div>
      <div class="head-contain-right">
        <div class="more-contain">
          <div class="more" @click="tomore">
            <div class="more-title">更多</div>
            <div class="more-icon">
              <el-image class="elimage" :src="moreicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-item" v-for="(item, index) in vrlist" :key="index">
        <div class="china_class_main">
          <template v-if="item.level == '国家级'">
            <div class="image-contain" @click="todetail(item.id)">
              <el-image class="elimage" :src="item.cover" />
            </div>
            <div class="china_class">
              <span>{{ item.level }}</span>
            </div>
          </template>
          <template v-else-if="item.level == '校级'">
            <div class="image-contain" @click="todetail(item.id)">
              <el-image class="elimage" :src="item.cover" />
            </div>
            <div class="china_class_1">
              <span>{{ item.level }}</span>
            </div>
          </template>
          <template v-else-if="item.level == '省级'">
            <div class="image-contain" @click="todetail(item.id)">
              <el-image class="elimage" :src="item.cover" />
            </div>
            <div class="china_class_2">
              <span>{{ item.level }}</span>
            </div>
          </template>
          <template v-else>
            <div class="image-contain" @click="todetail(item.id)">
              <el-image class="elimage" :src="item.cover" />
            </div>
          </template>
        </div>
        <div class="bottomcontain">
          <div class="title1">{{ item.name }}</div>
          <div class="title2">
            <div class="name">{{ item.leader_name }}<span v-if="(item.leader_name && item.school_name)"
                style="padding: 0px 4px;">|</span>{{
                    item.school_name
                }}</div>
            <div class="rightimage">
              <el-image class="elimage" :src="image3"></el-image>
              <span class="text">{{ item.join_count | cutNumber }}人</span>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-item" v-for="(item, index) in emptylist" :key="index + '_dd332jjk_545kka'"></div>
    </div>
  </div>
</template>

<script>
import { getVRList } from "@/api/vr";
export default {
  name: "Virtualsimulationexperiment",
  components: {},
  data() {
    return {
      vrlist: [],
      emptylist: [],
      image: require("@/assets/new/虚拟仿真.png"),
      moreicon: require("@/assets/new/right.png"),
      testimage: require("@/assets/test.png"),
      image3: require("@/assets/new/people.png"),
      image01: require("@/assets/new2/1.jpg"),
      image02: require("@/assets/new2/2.jpg"),
      image03: require("@/assets/new2/3.jpg"),
      image04: require("@/assets/new2/4.jpg"),
      image05: require("@/assets/new2/5.jpg"),
      image06: require("@/assets/new2/6.jpg"),
      image07: require("@/assets/new2/7.jpg"),
      image08: require("@/assets/new2/8.jpg"),
      image09: require("@/assets/new2/9.jpg"),
      image10: require("@/assets/new2/10.jpg"),
    };
  },
  filters: {
    cutNumber(value) {
      if (value > 9999) {
        let num = (Math.floor(value / 1000) / 10) + 'w';
        return num
      } else {
        return value
      }
    }
  },
  computed: {},
  mounted() {
    this.getVRList({
      per_page: 11,
      page: 1,
      is_show_by_rules: 1,
    });
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    getVRList(params) {
      this.vrlist = [];
      this.emptylist = [];
      getVRList(params)
        .then((res) => {
          if (res.data && res.data.data) {
            let itemlist = res.data.data;
            for (let i = 0; i < 11; i++) {
              if (itemlist[i]) {
                this.vrlist.push(itemlist[i]);
              } else {
                this.emptylist.push({});
              }
            }
          }
        })
        .catch((error) => { });
    },
    tomore() {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/mainpage",
      });
    },

    todetail(id) {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulationexperiment {

  width: 1760px;
  height: 100%;

  .head-contain {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .head-contain-left {
      height: 100%;
      width: 64px;
    }

    .head-contain-center {
      height: 100%;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;

        .title-icon {
          margin-right: 10px;
          width: 32px;
          height: 32px;

          .elimage {
            width: 100%;
            height: 100%;
          }
        }

        .title-text {
          // margin-left: 10px;
          font-size: 30px;

          font-weight: bold;
          color: #000000;
        }
      }
    }

    .head-contain-right {
      //   border: 1px solid red;
      height: 100%;

      //   width: 100px;
      .more-contain {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .more {
          width: 64px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .more-icon {
            margin-right: 10px;

            .elimage {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }

  .content {
    // border: 1px solid rgb(0, 255, 0);
    // height: 365px;
    margin-top: 20px;

    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    .content-item {
      border: 1px solid transparent;
      box-sizing: border-box;
      // height: 205px;
      width: 330px;
      flex-shrink: 1;
      cursor: pointer;
      // margin: 0px 10px;
      width: calc((100% - 120px) / 7);
      float: left;
      border-radius: 5px 5px 0px 0px;

      .china_class_main {
        position: relative;

        .image-contain {
          width: 100%;
          height: 140px;
          overflow: hidden;
          border-radius: 5px;

          .elimage {
            display: inline-block;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            border-radius: 5px;
          }

          .elimage:hover,
          .elimage:focus,
          .elimage:active {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }

          .elimage {
            width: 100%;
            height: 100%;
          }
        }

        .china_class {
          position: absolute;
          top: 0px;
          right: 0;
          background: #ff0c0c;
          font-size: 12px;
          color: #fff;
          width: 42px;
          height: 20px;
          line-height: 18px;
          text-align: center;
          border-radius: 0px 5px 0px 10px;
        }

        .china_class_1 {
          position: absolute;
          top: 0px;
          right: 0;
          background: #4980fe;
          font-size: 12px;
          color: #fff;
          width: 42px;
          height: 20px;
          line-height: 18px;
          text-align: center;
          border-radius: 0px 5px 0px 10px;
        }

        .china_class_2 {
          position: absolute;
          top: 0px;
          right: 0;
          background: #FBC248;
          font-size: 12px;
          color: #fff;
          width: 42px;
          height: 20px;
          line-height: 18px;
          text-align: center;
          border-radius: 0px 5px 0px 10px;
        }
      }

      .bottomcontain {
        padding: 20px 0px 0px;
        background: #ffffff;

        .title1 {
          // margin-left: 10px;
          text-align: left;
          font-size: 16px;
          text-overflow: ellipsis;
          font-weight: 500;
          color: #222222;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }

        .title2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            // margin-left: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
          }

          .rightimage {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .elimage {
              width: 16px;
              height: 16px;
            }

            .text {
              // margin-right: 10px;
              margin-left: 4px;
              font-size: 12px;
              text-align: left;
              color: #999;
            }
          }
        }
      }
    }

    .content-item:nth-child(1) {
      width: calc(((100% - 120px) / 7)*2 + 20px);
      height: calc((212px * 2) + 25px);

      .image-contain {
        height: calc(212px + 165px);

        .elimage {
          border-radius: 5px 5px 0px 0px;
        }
      }

      .bottomcontain {
        background-color: rgba(249, 249, 249, 1);
        border-radius: 0px 0px 5px 5px;
        padding: 20px 10px;
      }

      .elimage:hover,
      .elimage:focus,
      .elimage:active {
        -webkit-transform: scale(1.2) !important;
        transform: scale(1.2) !important;
      }
    }

    .content-item:nth-child(n+7) {
      margin-top: 25px;
    }

    .content-item:not(:first-child) {
      margin-left: 20px;
    }

    .empty-item {
      // border: 1px solid red;
      height: 100%;
      width: 330px;
      margin: 0px 10px;

      flex-shrink: 1;
    }
  }
}
</style>
